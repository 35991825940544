.error-row:hover {
    cursor: pointer;
}


/* Selected/checked/highlighted colors =================================*/
.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-highlight + tr.p-datatable-row-expansion {
    background: #99ccf9;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-highlight,
.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight  {
    background-color: #99ccf9;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight + tr.p-datatable-row-expansion {
    background-color: #99ccf9;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > .p-highlight {
    border-top: 1px solid #E3F2FD;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > .p-highlight + tr.p-datatable-row-expansion {
    border-bottom: 1px solid #E3F2FD
}



/* Alternating Color =================================*/
.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background-color: #E3F2FD;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + tr.p-datatable-row-expansion {
    background-color: #E3F2FD
}



/* Expanded Row =================================*/
.error-table.p-datatable.p-datatable-striped .p-datatable-tbody tr.p-datatable-row-expansion * {
    background-color: inherit;
    border: none;
    font-size: 12px;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody tr.p-datatable-row-expansion th {
    padding: 6.5px 6.5px 0 6.5px;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody tr.p-datatable-row-expansion th {
    padding: 6.5px 6.5px 0 6.5px;
}

.error-table.p-datatable.p-datatable-striped .p-datatable-tbody tr.p-datatable-row-expansion td {
    padding: 0 6.5px 6.5px 6.5px;
    align-items: start;
}

/* Expanded row table header overlaps filter row. Reset z-index */
.p-datatable-scrollable .p-datatable-row-expansion .p-datatable-thead {
    z-index: auto;
}



/* Misc =================================*/

/* IMPROVE: make more specific */
.error-table td {
    word-break: break-all;
}

.error-table td a {
    color: #1874CD;
    font-weight: 500;
}

.newman-container {
    word-wrap: break-word;
    word-break: keep-all;
    position: relative;
    margin: 40px auto 0;
    width: 420px;
  }

  .ah-ah-ah {
    height: auto;
    width: 300px;
    outline: 10px solid #FF8BC0
  }

  .speech-bubble-svg path {
    stroke: #FF8BC0;
    fill: #456791;
  }

  .speech-bubble-svg {
    width: 200px;
    height: auto;
    position: absolute;
    right: 10px;
    top: -40px;
  }

  .newman-text {
    position: absolute;
    right: 36px;
    top: 5px;
    font-size: 1.4em;
    font-weight: 600;
    color: #67CBBA;
    width: 150px;
    height: 90px;
    text-align: center;
    line-height: 22px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }
