html {
    font-size: 13px;
}

.controls-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.controls-left {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: left;
    margin-left: auto;
    padding: 5px;
}

.controls-right {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: right;
    margin-left: auto;
    padding: 5px;
}

.controls-left > *, .controls-right > * {
    margin: 10px;
}

.controls-container .p-dropdown {
    color: #ffffff;
    background: #2196F3;
    border: 1px solid #2196F3;
}

.controls-container .controls-right .p-dropdown {
    border-left-color: #ffffff;
    border-radius: 0 3px 3px 0;
}

.controls-container .p-dropdown * {
    color: #ffffff;
}
